<template>
  <div class="mt-2">
    <button class="mb-2 btn btn-default btn-sm" @click="this.$router.go(-1)">
      Back
    </button>
    <div class="card shadow w-100 p-4 pb-6">
      <div class="row mb-4 text-center">
        <div class="col-md-12">
          <h3>Update Field</h3>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-md-6">
          <label class="form-control-label">Name</label>
          <span class="ml-1 text-danger">*</span>
          <input
            class="form-control form-control-alternative"
            placeholder="Provide Name"
            v-model="data.named"
            rows="4"
          />
          <label
            v-for="error in errors.named"
            :key="error"
            class="form-control-flush text-danger text-sm"
            >{{ error }}</label
          >
        </div>
        <div class="col-md-6">
          <label class="form-control-label">Type</label>
          <span class="ml-1 text-danger">*</span>
          <input
            class="form-control form-control-alternative"
            placeholder="Prodivde Type"
            v-model="data.type"
            rows="4"
          />
          <label
            v-for="error in errors.type"
            :key="error"
            class="form-control-flush text-danger text-sm"
            >{{ error }}</label
          >
        </div> 
      </div>
      

      <div class="row">
        <div class="col-md-8 mt-2">
          <button class="btn btn-secondary px-6" @click="this.$router.go(-1)">
            Cancel
          </button>
          <!-- <button-loader :title="btnTitle" :classes="classes" :show_loader="show_loader" @click="saveServiceCatalog()"/> -->
          <button
            :disabled="show_loader"
            class="btn btn-md btn-default my-2 px-4"
            :style="!show_loader ? '' : styleObject"
            @click="updateField()"
          >
            Update Field
            <i v-if="show_loader" class="fa fa-spinner fa-spin"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { alert } from "../../utils/alertUtils";
export default {
  data() {
    return {
      selected: 0,
      data: {},
      errors: {},

      // button styles
      styleObject: {
        cursor: "not-allowed",
      },
      btnTitle: "Add Field",
      show_loader: false,
      classes: "btn btn-md btn-default my-2 px-4",
    };
  },
  methods: {
    getField: function() {
      const ref = doc(db, "fields", this.$route.params.uuid);
      getDoc(ref)
        .then((docSnap) => {
          if (docSnap.exists()) {
            this.data = docSnap.data();
          }
        })
        .then(() => {
          this.data_loaded = true;
        })
        .catch(() => {
          this.data_loaded = false;
        });
      console.log("get data");
    },

    updateField: function() {
      this.show_loader = true;
      // const FieldRef = Field(db, "services").doc(this.data.name)
      updateDoc(doc(db, "fields", this.$route.params.uuid), {
        ...this.data,
      })
        .then(() => {
          this.show_loader = false;
          console.log("updated successfully");
          alert.success_center("You have successfully updated Field");
          this.$router.push({ name: "Fields" });
        })
        .catch((e) => {
          alert.error("an error occured please try again");
          console.log(e);
        });
    },
  },
  created(){
      this.getField()
  }
};
</script>

<style lang="scss" scoped></style>
